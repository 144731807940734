/* global clearInterval, console, setInterval */

/* eslint-disable @typescript-eslint/no-unused-vars */
/* global console setInterval, clearInterval */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getFirestore, getDoc, doc } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyBE4jvTTaRNKN0iXNd_B_AP4Xrwl4EXqYs",
  authDomain: "dedupeone.firebaseapp.com",
  databaseURL: "https://dedupeone-default-rtdb.firebaseio.com",
  projectId: "dedupeone",
  storageBucket: "dedupeone.appspot.com",
  messagingSenderId: "939637555230",
  appId: "1:939637555230:web:615611b6fa04962e56ae0f",
  measurementId: "G-JDNL35CYD1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
/**
 * Converts numbers to text representation of money asynchronously.
 * @customfunction
 * @param {number[][]} numbers The numbers to convert.
 * @param {string} currency Optional. The currency to use. Defaults to 'usd'.
 * @param {string} subunit Optional. The subunit of the currency. Defaults to ''.
 * @returns {Promise<string[][]>} A promise resolving to the text representation of the numbers as money.
 */

async function MONEYTEXT(numbers, currency = "usd", subunit = "") {
  if (localStorage.getItem("userEmail")) {
    function todayDate() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      today = yyyy + "-" + mm + "-" + dd;
      return today;
    }

    function dateCheck(subscription_ending_date) {
      var today = todayDate();
      var today_date = new Date(today);
      var subscription_end_date = new Date(subscription_ending_date);
      return subscription_end_date.getTime() > today_date.getTime();
    }

    try {
      // Function to check subscription status
      async function checkSubscription() {
        var email = localStorage.getItem("userEmail");
        var emailSubscriptionExist = false;
        var subscriptionNotExpired = false;
        const docRef = doc(db, "payments", "numbertowords");
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        if (data) {
          const emails = Object.keys(data);
          const dates = Object.values(data);
          for (var i = 0; i < emails.length; i++) {
            if (email == emails[i]) {
              emailSubscriptionExist = true;
              subscriptionNotExpired = dateCheck(dates[i]);
              break;
            }
          }
        }
        return emailSubscriptionExist && subscriptionNotExpired;
      }

      // Check subscription status
      var sub_status = await checkSubscription();

      var tries = parseInt(localStorage.getItem("tries"));
      if (isNaN(tries)) {
        tries = 0;
        localStorage.setItem("tries", tries);
      }

      // Perform the subscription check and process accordingly
      if (sub_status || tries < 7) {
        tries++;
        localStorage.setItem("tries", tries);

        // Your existing code for fetching results and processing them
        const batchSize = 1000;
        if (!Array.isArray(numbers)) {
          numbers = [[numbers]];
        }
        const filteredNumbers = numbers.filter(
          (subArray) => !subArray.every((value) => value === 0 || value === null || value === "")
        );
        const batches = [];
        for (let i = 0; i < filteredNumbers.length; i += batchSize) {
          batches.push(filteredNumbers.slice(i, i + batchSize));
        }
        const results = [];
        for (const batch of batches) {
          const flattenedNumbers = batch.flat();
          const encodedNumbers = flattenedNumbers.map((num) => encodeURIComponent(num));
          const queryString = encodedNumbers.join(",");
          const url = `https://numbertowords-opal.vercel.app/numbertocurrency?numbers=${queryString}&currency=${currency}&cent=${subunit}`;
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          let wordsArray = data.wordsArray;
          for (let i = 0; i < wordsArray.length; i++) {
            wordsArray[i] = wordsArray[i].replace(/ and (null|cents)/g, "").trim();
          }
          results.push(...wordsArray.map((word) => [word]));
        }
        if (sub_status) {
          // Return all values if subscription is active
          return results;
        } else {
          if (results.length < 20) {
            // If user is on free trial and result records are less than 20, return the results without additional messages
            return results;
          } else {
            let sliceR = results.slice(0, 20);
            // If user is on free trial and result records are 20 or more, return top 20 values with additional messages
            sliceR.splice(
              20,
              0,
              ["• Only Top 20 records shown. Please subscribe to enjoy unlimited records lookups"],
              ["• To subscribe, click on upgrade button then click on number to words"],
              ["• for assistance contact us on smallpdfkenya@gmail.com"]
            );
            return sliceR;
          }
        }
      } else {
        // Return appropriate message if subscription is not active
        return [
          ["• Free runs already exhausted"],
          ["• To subscribe, click on upgrade on the side menu"],
          ["• for assistance contact us on smallpdfkenya@gmail.com"],
        ];
      }
    } catch (error) {
      throw error;
    }
  } else {
    return [["• Please login to use formula"]];
  }
}

export { MONEYTEXT };

/**
 * Converts numbers to text representation asynchronously.
 * @customfunction
 * @param {number[][]} numbers The numbers to convert.
 * @param {string} language Optional. The language for conversion. Defaults to 'en-us'.
 * @returns {Promise<string[][]>} A promise resolving to the text representation of the numbers.
 */
async function NUMBERTOTEXT(numbers, language = "en-us") {
  if (localStorage.getItem("userEmail")) {
    function todayDate() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      today = yyyy + "-" + mm + "-" + dd;
      return today;
    }

    function dateCheck(subscription_ending_date) {
      var today = todayDate();
      var today_date = new Date(today);
      var subscription_end_date = new Date(subscription_ending_date);
      return subscription_end_date.getTime() > today_date.getTime();
    }

    try {
      // Function to check subscription status
      async function checkSubscription() {
        var email = localStorage.getItem("userEmail");
        var emailSubscriptionExist = false;
        var subscriptionNotExpired = false;
        const docRef = doc(db, "payments", "numbertowords");
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        if (data) {
          const emails = Object.keys(data);
          const dates = Object.values(data);
          for (var i = 0; i < emails.length; i++) {
            if (email == emails[i]) {
              emailSubscriptionExist = true;
              subscriptionNotExpired = dateCheck(dates[i]);
              break;
            }
          }
        }
        return emailSubscriptionExist && subscriptionNotExpired;
      }

      // Check subscription status
      var sub_status = await checkSubscription();

      var tries = parseInt(localStorage.getItem("tries"));
      if (isNaN(tries)) {
        tries = 0;
        localStorage.setItem("tries", tries);
      }

      // Perform the subscription check and process accordingly
      if (sub_status || tries < 7) {
        tries++;
        localStorage.setItem("tries", tries);

        // Your existing code for fetching results and processing them
        const batchSize = 1000; // Define a reasonable batch size

        // Filter out subarrays containing only 0 or null
        const filteredNumbers = numbers.filter(
          (subArray) => !subArray.every((value) => value === 0 || value === null || value === "")
        );

        const batches = [];
        for (let i = 0; i < filteredNumbers.length; i += batchSize) {
          batches.push(filteredNumbers.slice(i, i + batchSize));
        }

        const results = [];
        for (const batch of batches) {
          // Flatten the array of arrays into a single array
          const flattenedNumbers = batch.flat();

          // Encode each number individually
          const encodedNumbers = flattenedNumbers.map((num) => encodeURIComponent(num));

          // Construct the query string by joining the encoded numbers with '&numbers=' separator
          const queryString = encodedNumbers.join("&numbers=");

          // Construct the URL with the query string and language parameter
          const url = `https://numbertowords-opal.vercel.app/numbertotext?numbers=${queryString}&language=${language}`;

          // Fetch data from the URL
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();

          // Process the conversion result
          results.push(...data.wordsArray);
        }

        // Clear the original numbers array
        numbers.length = 0;

        // Push each word as a new array element
        results.forEach((word) => {
          numbers.push([word]);
        });
        if (sub_status) {
          // Return all values if subscription is active
          return numbers;
        } else {
          if (numbers.length < 20) {
            // If user is on free trial and result records are less than 20, return the results without additional messages
            return numbers;
          } else {
            let sliceR = numbers.slice(0, 20);
            // If user is on free trial and result records are 20 or more, return top 20 values with additional messages
            sliceR.splice(
              20,
              0,
              ["• Only Top 20 records shown.Please subscribe to enjoy unlimited records lookups"],
              ["• To subscribe, click on upgrade button then click on number to words"],
              ["• for assistance contact us on smallpdfkenya@gmail.com"]
            );
            return sliceR;
          }
        }
      } else {
        // Return appropriate message if subscription is not active
        return [
          ["• Free runs already exhausted"],
          ["• To subscribe, click on upgrade on the side menu"],
          ["• for assistance contact us on smallpdfkenya@gmail.com"],
        ];
      }
    } catch (error) {
      throw error;
    }
  } else {
    return [["• Please login to use formula"]];
  }
}

// Export the function for use elsewhere
export { NUMBERTOTEXT };

/**
 * Converts numbers to text representation asynchronously.
 * @customfunction
 * @param {number[][]} numbers The numbers to convert.
 * @returns {Promise<string[][]>} A promise resolving to the text representation of the numbers.
 */
async function NUMBERTEXT(numbers) {
  if (localStorage.getItem("userEmail")) {
    function todayDate() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      today = yyyy + "-" + mm + "-" + dd;
      return today;
    }

    function dateCheck(subscription_ending_date) {
      var today = todayDate();
      var today_date = new Date(today);
      var subscription_end_date = new Date(subscription_ending_date);
      return subscription_end_date.getTime() > today_date.getTime();
    }

    try {
      // Function to check subscription status
      async function checkSubscription() {
        var email = localStorage.getItem("userEmail");
        var emailSubscriptionExist = false;
        var subscriptionNotExpired = false;
        const docRef = doc(db, "payments", "numbertowords");
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        if (data) {
          const emails = Object.keys(data);
          const dates = Object.values(data);
          for (var i = 0; i < emails.length; i++) {
            if (email == emails[i]) {
              emailSubscriptionExist = true;
              subscriptionNotExpired = dateCheck(dates[i]);
              break;
            }
          }
        }
        return emailSubscriptionExist && subscriptionNotExpired;
      }

      // Check subscription status
      var sub_status = await checkSubscription();

      var tries = parseInt(localStorage.getItem("tries"));
      if (isNaN(tries)) {
        tries = 0;
        localStorage.setItem("tries", tries);
      }

      // Perform the subscription check and process accordingly
      if (sub_status || tries < 7) {
        tries++;
        localStorage.setItem("tries", tries);

        const batchSize = 1000; // Define a reasonable batch size

        // Filter out subarrays containing only 0 or null
        const filteredNumbers = numbers.filter(
          (subArray) => !subArray.every((value) => value === 0 || value === null || value === "")
        );

        const batches = [];
        for (let i = 0; i < filteredNumbers.length; i += batchSize) {
          batches.push(filteredNumbers.slice(i, i + batchSize));
        }

        const results = [];
        for (const batch of batches) {
          // Encode each number individually
          const encodedBatch = batch.map((num) => encodeURIComponent(num)).join("&numbers=");

          // Fetch data from the URL
          const response = await fetch(`https://numbertowords-opal.vercel.app/convert?numbers=${encodedBatch}`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          results.push(...data.wordsArray.map((word) => [word])); // Convert each word to an array
        }
        if (sub_status) {
          // Return all values if subscription is active
          return results;
        } else {
          if (results.length < 20) {
            // If user is on free trial and result records are less than 20, return the results without additional messages
            return results;
          } else {
            let sliceR = results.slice(0, 20);
            // If user is on free trial and result records are 20 or more, return top 20 values with additional messages
            sliceR.splice(
              20,
              0,
              ["• Only Top 20 records shown.Please subscribe to enjoy unlimited records lookups"],
              ["• To subscribe, click on upgrade button then click on number to words"],
              ["• for assistance contact us on smallpdfkenya@gmail.com"]
            );
            return sliceR;
          }
        }
      } else {
        // Return appropriate message if subscription is not active
        return [
          ["• Free runs already exhausted"],
          ["• To subscribe, click on upgrade on the side menu"],
          ["• for assistance contact us on smallpdfkenya@gmail.com"],
        ];
      }
    } catch (error) {
      throw error;
    }
  } else {
    return [["• Please login to use formula"]];
  }
}

// Export the function for use elsewhere
export { NUMBERTEXT };

CustomFunctions.associate("MONEYTEXT", MONEYTEXT);
CustomFunctions.associate("NUMBERTOTEXT", NUMBERTOTEXT);
CustomFunctions.associate("NUMBERTEXT", NUMBERTEXT);